const newWindowString = ' (öffnet neues Fenster)';

export const flattenTelNumber = (telNumber: string) => {
  return telNumber.replace(/[^0-9]/g, '');
};

const addLinkHint = (href: string, currentText: string) => {
  return `${currentText}${
    href.startsWith('http')
      ? currentText.indexOf(newWindowString) === -1
        ? newWindowString
        : ''
      : ''
  }`.trim();
};

export const addAriaLabel = (href, children, ariaLabel) => {
  if (ariaLabel) {
    return ariaLabel;
  }
  if (!children) {
    return undefined;
  }
  if (typeof children !== 'object' && typeof children !== 'string') {
    return undefined;
  }
  if (typeof children === 'string') {
    return addLinkHint(href, children);
  }
  if (typeof children === 'object') {
    const element: React.ReactElement = children as React.ReactElement;
    if (element.type !== undefined) {
      // nicht aus Richtext
      return undefined;
    }
    // geschachtelte Kindelemente z.B. wg. ersetzten Abkürzungen
    if (element.type !== 'span') {
      const child = children[0];
      if (typeof child !== 'string') {
        if (child.props && child.props['data-unchanged-text']) {
          return addLinkHint(href, child.props['data-unchanged-text']);
        }
        // das müsste aber vorhanden sein
        return undefined;
      }
      return addLinkHint(href, child);
    }
  }
  return undefined;
};
