import { Icon, IconVariant } from '@/components/Icon';
import LinkWrapper from '@/components/LinkWrapper';
import classnames from 'classnames';

export function NavigationItem({ item, isCurrent, onClick, onClose }) {
  const handleOnClick = () => {
    onClick(item.id);
  };

  const renderLabel = () => {
    return (
      <span className="NavigationItem__label">
        {item.icon && item.icon !== 'none' && <Icon variant={item.icon} />}
        <b>{item.shortTitle || item.title}</b>
      </span>
    );
  };

  return (
    <li
      className={classnames('NavigationItem', {
        'NavigationItem--current': isCurrent,
      })}
    >
      {item.link ? (
        <LinkWrapper
          className="NavigationItem__text"
          href={item.link?.path}
          title={item.title}
          onClick={() => {
            handleOnClick();
            onClose();
          }}
          tabIndex={0}
        >
          {renderLabel()}
          {item.badge && (
            <span className="NavigationItem__badge">{item.badge}</span>
          )}
        </LinkWrapper>
      ) : (
        <button
          className="NavigationItem__text"
          onClick={handleOnClick}
          title={item.title}
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleOnClick();
            }
          }}
        >
          {renderLabel()}
          <span className="NavigationItem__container">
            {item.badge && (
              <span className="NavigationItem__badge NavigationItem__badge--right">
                {item.badge}
              </span>
            )}
            <Icon variant={IconVariant.ChevronRight} />
          </span>
        </button>
      )}
    </li>
  );
}
