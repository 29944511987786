import { addAriaLabel, flattenTelNumber } from '@/utilities/linkUtils';
import Link from 'next/link';
import React from 'react';

interface LinkWrapperProps {
  href: string;
  children: React.ReactNode;
  title?: string;
  ariaLabel?: string;
  accessibilityAdjustments?: boolean;
  [key: string]: any;
}
export default function LinkWrapper({
  href,
  children,
  title,
  ariaLabel,
  accessibilityAdjustments,
  ...rest
}: LinkWrapperProps) {
  const linkTitle = () => {
    return title
      ? title
      : href.startsWith('mailto:')
      ? `Email an: ${href.replace('mailto:', '')}`
      : href.startsWith('tel:')
      ? `Telefonnummer: ${flattenTelNumber(href)}`
      : href;
  };

  return (
    <Link
      href={href}
      title={linkTitle()}
      aria-label={addAriaLabel(href, children, ariaLabel)}
      {...rest}
      prefetch={false}
      target={
        href.startsWith('mailto:')
          ? '_top'
          : href.startsWith('http')
          ? '_blank'
          : undefined
      }
    >
      {children}
    </Link>
  );
}
