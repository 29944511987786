export default function SkipLink() {
  const skipLinks = [
    { href: '#navigation', label: 'Zur Hauptnavigation springen' },
    { href: '#seiteninhalt', label: 'Zum Seiteninhalt springen' },
    { href: '#footer', label: 'Zum Footer springen' },
  ];

  return (
    <div
      className="skip-links__container"
      role="navigation"
      aria-label="Metanavigation"
    >
      {skipLinks.map((link, index) => (
        <a key={index} href={link.href} className="skip-link__target">
          <span className="icon" aria-hidden="true">
            {'> '}
          </span>
          <span className="target__content">{link.label}</span>
        </a>
      ))}
    </div>
  );
}
