import { CmsNavigationRootComponent } from '@/components/CmsInterfaces';
import LinkWrapper from '@/components/LinkWrapper';
import navigation from '@/config/navigation';
import classnames from 'classnames';
import { useRouter } from 'next/router';

export interface ListMenuProps {
  currentItem: string | null;
  onClick: (item: string) => void;
  parentPage: string | null;
}

enum ThemeNames {
  'lotto6aus49' = '4',
  'eurojackpot' = '1',
  'gluecksspirale' = '2',
  'keno' = '3',
  'default' = '0',
  'rubbellose' = '11',
}

export const ListMenu: React.FC<React.PropsWithChildren<ListMenuProps>> = (
  props
) => {
  const isCurrentItem = (item: CmsNavigationRootComponent) => {
    return item.id === props.currentItem;
  };
  const router = useRouter();

  const handleOnClick = (
    item: CmsNavigationRootComponent,
    $event?: React.MouseEvent<HTMLElement>
  ) => {
    if ($event) {
      $event.preventDefault();
      const isMouseClick = $event.nativeEvent.sourceCapabilities;

      // Bei Mausklick die Navigation direkt öffnen
      if (isMouseClick && isMouseClick.firesTouchEvents === false) {
        props.onClick(item.id);
      } else {
        // Bei Screen Reader Link-Navigation
        const href = (item.overview && item.overview.path) || '#';
        router.push(href);
      }
    }
  };

  return (
    <nav className="ListMenu" aria-label="Hauptnavigation" tabIndex={0} id="">
      <ul className="ListMenu__list">
        {navigation.map((item) => (
          <li
            key={item.id}
            className={classnames('ListMenu__item', {
              'ListMenu__item--current': isCurrentItem(item),
            })}
          >
            <LinkWrapper
              className={classnames('ListMenu__link', {
                'ListMenu__link--currentParent': props.parentPage
                  ? ThemeNames[props.parentPage] === item.theme
                  : false,
              })}
              href={(item.overview && item.overview.path) || '#'}
              onClick={($event) => handleOnClick(item, $event)}
              tabIndex={0}
            >
              {item.shortTitle || item.title}
            </LinkWrapper>
          </li>
        ))}
      </ul>
    </nav>
  );
};
